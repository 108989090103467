import React from 'react'
import Layout from '../components/layouts/layout'
import { graphql } from 'gatsby'
import SEO from '../components/layouts/seo';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import HomepageCTA from '../components/homepage/homepage-cta';
// @ts-ignore
import NotFoundAnimated from '../svgs/not-found-animated.svg' 
// @ts-ignore
import NotFound from '../svgs/not-found.svg' 
// @ts-ignore
import BrYellow from '../svgs/br-yellow.svg'
// @ts-ignore
import ArrowRightGrey from '../svgs/arrow-right-grey.svg' 

interface props {
  data: any
  browser: string
  transitionStatus: string
}

interface state {
}

class NotFoundPage extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    let {transitionStatus} = this.props;

    return (
      <>
        <SEO
          title={'404'} 
          description={'404'} 
        />
        <Layout 
          data={data}
          class={`not-found`}
          transitionStatus={transitionStatus}
        >
          <div className="not-found__content inner-container">

            {this.props.browser == 'ie' || this.props.browser == 'edge' ?
              <NotFound className="not-found__content-svg"/>
            :
              <NotFoundAnimated className="not-found__content-svg"/>
            }

            <h2 className="not-found__content-title">Page not found!</h2>
            <BrYellow/>
            <p className="not-found__content-text">Sorry, we can’t find that page! It might be an old link or maybe it moved.</p>
            <div className="homepage-cta__left">
              {data.homepageCTA.ctas.map((cta, index) => {
                return (
                  <button 
                    className="homepage-cta__cta" 
                    key={`cta-` + index}
                    onClick={event => {
                      event.preventDefault()
                      navigate("/learning-areas/", {state: { filter: cta.ctaTopic }})
                    }}
                  >
                    <p className="homepage-cta__cta-heading">{cta.ctaTopic}</p>
                    <img className="homepage-cta__cta-image" src={cta.ctaImage.url} alt={cta.ctaImage.alt}/>
                    <div className="homepage-cta__cta-footer">
                      <span className="homepage-cta__footer-title">Discover {cta.ctaTopic}</span>
                      <ArrowRightGrey/>
                    </div>
                  </button>
                )
              })}
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    site: datoCmsSite {
      ...siteData
    }
    nav: datoCmsNavigation {
      ...navData
    }
    footer: datoCmsFooter {
      ...footerData
    }
    homepageCTA: datoCmsHomepageCtaSection {
      ctaTitle
      ctaDescription
      ctas {
        ctaImage {
          alt
          url
        }
        ctaTopic
      }
    }
  }
`

export default connect(
  state => ({ 
    browser: state.browser,
  })
)(NotFoundPage);